import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";
import { USER } from "../constants/consts";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return { ...response.data, statusCode: response.status };
  },
  (error) => {
    let notificationParam = {
      message: "",
    };
    //error.response.status === 400 ||
    // Remove token and redirect
    if (error.response.status === 403 || error.response.status === 401) {
      notificationParam.message =
        error.response.status === 403
          ? "Permission Denied"
          : "Authentication Fail";
      notificationParam.description = "Please login again";
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(USER);
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (error.response.status === 404) {
      notificationParam.message = error?.response?.data?.message || "Not Found";
    }

    if (error.response.status === 400) {
      notificationParam.message =
        error?.response?.data?.message || "Bad request";
    }

    if (error.response.status === 500) {
      notificationParam.message =
        error?.response?.data?.message || "Internal Server Error";
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
    }

    if (notificationParam.message !== "") {
      notification.error(notificationParam);
    }
    return Promise.reject({
      ...error.response.data,
      statusCode: error.response.status,
    });
  }
);

export default service;
