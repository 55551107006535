import { EditOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Spin,
} from "antd";
import Icon from "components/util-components/Icon";
import { REGEX } from "constants/patterns";
import { useState } from "react";
import { connect } from "react-redux";
import { signOut } from "redux/actions/Auth";
import { useTranslation } from "../../hooks/useTranslation";
import JwtAuthService from "services/JwtAuthService";

const ModalChangePass = ({ isVisible, onCancel }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        console.log(val);
        setLoading(true);
        JwtAuthService.changePass({
          old_password: val.oldPass,
          password: val.newPass,
        })
          .then((res) => {
            if (res?.status) {
              notification.info({
                message: t("Successfully"),
                description: t("Update pass success"),
                placement: "topRight",
              });
              onCancel();
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      })
      .catch(() => {});
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
          }}
        >
          <Button onClick={onCancel}> {t("Cancel")}</Button>
          <Button onClick={handleSubmit} type="primary">
            {t("Change")}
          </Button>
        </div>
      }
      title={t("Change Password")}
      width={600}
    >
      <Spin spinning={loading}>
        <Form form={form} labelCol={{ span: 10 }} labelAlign="left">
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item
                  name="oldPass"
                  label={t("Old password")}
                  rules={[
                    {
                      required: true,
                      message: t("Please input your {name}", {
                        name: t("Old password"),
                      }),
                    },
                    {
                      pattern: REGEX.PASSWORD,
                      message: t("Please enter a validate {name}", {
                        name: t("password"),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          getFieldValue("newPass") &&
                          getFieldValue("newPass") === value
                        ) {
                          return form.setFields([
                            {
                              name: "newPass",
                              errors: [t("Duplicate password")],
                            },
                          ]);
                        } else {
                          form.setFields([
                            {
                              name: "newPass",
                              errors: null,
                            },
                          ]);
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              return (
                <Form.Item
                  name="newPass"
                  label={t("New password")}
                  rules={[
                    {
                      required: true,
                      message: t("Please input your {name}", {
                        name: t("New password"),
                      }),
                    },
                    {
                      pattern: REGEX.PASSWORD,
                      message: t("Please enter a validate {name}", {
                        name: t("password"),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (getFieldValue("oldPass") === value) {
                          return form.setFields([
                            {
                              name: "newPass",
                              errors: [t("Duplicate password")],
                            },
                          ]);
                        }
                        if (
                          getFieldValue("confirmPass") &&
                          getFieldValue("confirmPass") !== value
                        )
                          return form.setFields([
                            {
                              name: "confirmPass",
                              errors: [t("Password invalid")],
                            },
                          ]);
                        else
                          form.setFields([
                            {
                              name: "confirmPass",
                              errors: null,
                            },
                          ]);

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.newPass !== curValues.newPass
            }
            noStyle
          >
            {() => {
              return (
                <Form.Item
                  label={t("Confirm new password")}
                  name={"confirmPass"}
                  rules={[
                    {
                      required: true,
                      message: t("Please input your {name}", {
                        name: t("Confirm new password"),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPass") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t("Password invalid")));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export const NavProfile = ({ signOut }) => {
  const { t } = useTranslation();
  const profileImg = "/img/avatars/thumb-1.jpg";
  const userStorage = localStorage.getItem("user") || null;
  const user = userStorage ? JSON.parse(userStorage) : null;
  const [isOpenModalChangePass, setIsOpenModalChangePass] = useState(false);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            size={45}
            src={profileImg}
            icon={<UserOutlined />}
            style={{ backgroundColor: "#b7eb8f" }}
          />
          <div className="pl-3">
            <h4 className="mb-0">{user.full_name}</h4>
            <span className="text-muted">{user.role}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item
            key={"Edit profile"}
            onClick={() => setIsOpenModalChangePass(true)}
          >
            <Icon className="mr-3" type={EditOutlined} />
            <span className="font-weight-normal">{t("Edit profile")}</span>
          </Menu.Item>

          <Menu.Item key={"log out"} onClick={() => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">{t("Sign out")}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <div>
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <div style={{ cursor: "pointer" }}>
          <Avatar
            src={profileImg}
            icon={<UserOutlined />}
            style={{ backgroundColor: "#b7eb8f" }}
          />
        </div>
      </Dropdown>
      {isOpenModalChangePass && (
        <ModalChangePass
          isVisible={isOpenModalChangePass}
          onCancel={() => {
            setIsOpenModalChangePass(false);
          }}
        />
      )}
    </div>
  );
};

export default connect(null, { signOut })(NavProfile);
